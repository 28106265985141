html {
  scroll-behavior: smooth;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #dbba36, #40bb73, #f9e79f);
  height: 85vh;
  padding: 0 3rem;
  color: #ffffff;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #f9e79f;
    z-index: -1;
  }

  .hero-content {
    max-width: 50%;
    z-index: 1;
    color: #054821;

    h1 {
      font-size: 52px;
      font-family: 'Krub', sans-serif;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 24px;
      margin-top: 1rem;
      color: #ffffff;
    }

    .hero-buttons {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;

      .hero-button {
        padding: 1rem 2rem;
        border: 2px solid #054821;
        background-color: #054821;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: transparent;
          color: #054821;
        }
      }
    }
  }

  .hero-image {
    max-width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10rem 1rem;

    .hero-content {
      max-width: 100%;
      text-align: center;
    }

    .hero-image {
      margin-top: 2rem;
      max-width: 100%;
      img {
        height: auto;
      }
    }

    .hero-buttons .hero-button {
      padding: 0.8rem 1.5rem;
    }
  }
}