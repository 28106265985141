// src/components/landing/style/about.scss

.about {
    max-width: 1200px; /* Adjust to make the whole section width smaller */
    margin: 0 auto; /* Center the section */
    padding: 4rem 3rem;
  
    .about-boxes {
      display: flex;
      justify-content: space-between;
      gap: 1rem; /* Reduced gap to bring the cards closer */
      padding-bottom: 3rem;
  
      .about-box {
        width: 22%; /* Slightly reduced width for closer cards */
        background: linear-gradient(45deg, #e7ce67, #40BB73, #F9E79F); /* Linear gradient background */
        color: #ffffff;
        padding: 2rem;
        text-align: center;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 8px;
        }
  
        h2 {
          margin-top: 1rem;
          font-size: 22px; /* Adjusted font size */
          font-family: 'Krub', sans-serif;
          font-weight: bold;
          color: #054821;
        }
      }
  
      .about-box-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        .about-box-left,
        .about-box-right {
          width: 48%;
        }
  
        .about-box-left {
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 8px;
          }
        }
  
        .about-box-right {
          display: flex;
          justify-content: center;
          align-items: center;
          
          h2 {
            font-size: 22px; /* Adjusted font size */
            font-family: 'Krub', sans-serif;
            font-weight: bold;
          }
        }
      }
    }
  
    .about-details {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 3rem 3rem;
  
      .about-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; /* Align text and image from the top */
        width: 100%;
  
        .about-image {
          width: 45%;
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 10px;
          }
        }
  
        .about-text {
          width: 45%;
          text-align: left;
  
          h1 {
            font-size: 48px;
            font-family: 'Krub', sans-serif;
            font-weight: bold;
            color: #054821; /* Green color for text */
            margin-bottom: 1rem;
          }
  
          p {
            font-size: 20px;
            color: #000000;
            margin-bottom: 2rem;
          }
  
          .about-button {
            padding: 1rem 3rem;
            border: 2px solid #054821;
            background-color: #054821;
            color: #ffffff;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
            font-family: 'Krub', sans-serif;
            text-transform: uppercase;
  
            &:hover {
              background-color: transparent;
              color: #054821;
            }
          }
        }
      }
    }
  
    /* Responsive styles */
    @media (max-width: 768px) {
      .about-boxes {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        padding: 2rem 1rem;
  
        .about-box {
          width: 100%;
          text-align: center;
        }
  
        .about-box-content {
          flex-direction: column;
          align-items: center;
          
          .about-box-left,
          .about-box-right {
            width: 100%;
            text-align: center;
          }
        }
      }
  
      .about-details .about-content {
        flex-direction: column;
        align-items: center;
  
        .about-image,
        .about-text {
          width: 100%;
          text-align: center;
        }
  
        .about-button {
          width: 100%;
          padding: 1rem 2rem;
        }
      }
    }
  }
  