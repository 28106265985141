.services {
  /* Section 1 - Sign Up and Get 15% Off */
  .services-first-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 3rem;
    background: linear-gradient(45deg, #e7ce67, #40bb73, #f9e79f);

    .services-text {
      width: 50%;
      color: #ffffff;
      text-align: center;

      h2 {
        font-size: 40px;
        font-family: 'Krub', sans-serif;
        font-weight: bold;
        color: #054821;
        margin-bottom: 1rem;
      }

      .line {
        width: 80%;
        margin: 1rem auto;
        border-top: 4px solid #054821;
      }

      p {
        font-size: 24px;
        color: #054821;
        margin-bottom: 2rem;
      }
    }

    .services-image {
      width: 25%;
      margin-right: 8rem;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  /* Section 2 - Why Aronia Treasure? */
  .services-second-section {
    padding: 3rem;
    text-align: center;

    .why-aronia {
      h3 {
        font-size: 45px;
        font-family: 'Krub', sans-serif;
        font-weight: bold;
        color: #054821;
        margin-bottom: 1rem;
      }

      p {
        font-size: 20px;
        color: #000000;
        margin-bottom: 3rem;
        max-width: 800px;
        margin: 0 auto;
      }
    }

    .services-images {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-top: 5rem;

      .service-image {
        width: 40%;

        &:nth-child(2) {
          width: 45%;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
  }

  /* Section 3 - Stats Section */
  .services-stats {
    background: linear-gradient(45deg, #e7ce67, #40bb73, #f9e79f);
    padding: 2rem 3rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    text-align: center;

    .stat-item {
      width: 22%;

      h1 {
        font-size: 58px;
        font-family: 'Krub', sans-serif;
        font-weight: bold;
        color: #054821;
      }

      h2 {
        font-size: 28px;
        font-family: 'Krub', sans-serif;
        font-weight: bold;
        color: #054821;
      }
    }
  }

  /* Section 4 - Client Testimonials */
  .services-testimonials {
    padding: 3rem;
    text-align: center;
    background: #f7f7f7;

    h2 {
      font-size: 40px;
      font-family: 'Krub', sans-serif;
      font-weight: bold;
      color: #054821;
      margin-bottom: 1rem;
    }

    p {
      font-size: 20px;
      color: #555555;
      margin-bottom: 3rem;
    }

    .testimonials-cards {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .testimonial-card {
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 10px;
        width: 30%;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

        .circle-img {
          width: 120px;
          height: 120px;
          margin: 0 auto 1rem;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h3 {
          font-size: 22px;
          font-family: 'Krub', sans-serif;
          color: #054821;
          margin-bottom: 1rem;
        }

        p {
          font-size: 16px;
          color: #666666;
        }
      }
    }
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .services-first-section {
      flex-direction: column;
      text-align: center;

      .services-text {
        width: 100%;
        margin-bottom: 2rem;
      }

      .services-image {
        width: 100%;
        margin-left: 10rem;
      }
    }

    .services-second-section {
      padding: 2rem;
    }

    .services-images {
      flex-direction: column;
      gap: 1rem;
      align-items: center; // Center the images

      .service-image {
        width: 95% !important; // Increase the size of the images

        &:nth-child(2) {
          width: 95% !important; // Increase the size of the images
        }
      }
    }

    .services-stats {
      flex-direction: column;
      align-items: center;

      .stat-item {
        width: 80%;
        margin-bottom: 1.5rem;
      }
    }

    .services-testimonials {
      padding: 2rem;

      .testimonials-cards {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center; // Center the testimonial cards

        .testimonial-card {
          width: 95%; // Increase the size of the testimonial cards
        }
      }
    }
  }
}