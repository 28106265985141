html {
  scroll-behavior: smooth;
}

.navbar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1rem 2rem;
  font-family: 'Krub', sans-serif;
  color: #054821;

  .navbar-logo {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
  }

  .navbar-links {
    display: flex;
    align-items: center;
    gap: 2rem;
    list-style: none;

    li {
      color: #054821;
      font-weight: 600;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #ffffff;
      }

      &.navbar-button {
        padding: 0.5rem 1rem;
        border: 2px solid #054821;
        border-radius: 4px;
        background-color: #054821;
        color: #ffffff;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: transparent;
          color: #054821;
        }
      }
    }

    &.open {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      background-color: #ffffff;
      position: absolute;
      top: 78%;
      left: 0;
      right: 0;
      padding: 1rem 0;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .menu-icon {
      display: block;
    }

    .navbar-links {
      display: none;
    }

    .navbar-links.open {
      display: flex;
    }

    .navbar-logo {
      margin-right: auto;
    }
  }
}