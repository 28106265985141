.footer {
  background-color: #054821; /* Dark green background */
  color: white; /* White text color */
  padding: 3rem 2rem;

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: start; /* Align items to the top */
    flex-wrap: wrap;

    /* Left Section - Logo */
    .footer-logo {
      width: 100%; /* Logo takes up full width on mobile */
      text-align: center;
      margin-bottom: 2rem;

      .logo-text {
        font-size: 36px;
        font-weight: bold;
        font-family: 'Krub', sans-serif;
        color: white;
      }
    }

    /* Middle Section - Social Media Text and Icons */
    .footer-middle {
      width: 100%; /* Middle takes up full width on mobile */
      text-align: center;
      margin-bottom: 2rem;

      h2 {
        font-size: 20px; /* Adjust font size for h2 */
        font-weight: bold;
        font-family: 'Krub', sans-serif;
        color: white;
        margin-bottom: 1rem;
      }

      .footer-description {
        font-size: 16px;
        margin-top: 1rem;

        p {
          font-size: 16px;
          margin-bottom: 1rem;
        }
      }

      .social-icons {
        display: flex;
        justify-content: center;
        gap: 1rem;

        a {
          color: white;
          font-size: 24px;
          transition: color 0.3s;

          &:hover {
            color: #e7ce67; /* Hover effect color */
          }
        }
      }
    }

    /* Right Section - Location and Contact Information */
    .footer-right {
      width: 100%; /* Right section takes up full width on mobile */
      text-align: center;

      .footer-location,
      .footer-phone,
      .footer-email {
        display: flex;
        align-items: center;
        justify-content: center; /* Center items horizontally */
        flex-direction: column; /* Stack items vertically */
        margin-bottom: 1rem;
        text-align: center;

        .icon {
          margin-bottom: 0.5rem;
        }

        .footer-location-text,
        .footer-phone-number,
        .footer-email-address {
          font-size: 16px;
        }
      }
    }
  }

  /* Responsive styles */
  @media (min-width: 768px) {
    .footer-container {
      flex-direction: row;
      align-items: center;

      .footer-logo {
        width: 30%; /* Restore original width */
        text-align: left;
        margin-bottom: 0;
      }

      .footer-middle {
        width: 40%; /* Restore original width */
        margin-bottom: 0;
      }

      .footer-right {
        width: 25%; /* Restore original width */
        text-align: right;

        .footer-location,
        .footer-phone,
        .footer-email {
          justify-content: flex-start; /* Align items to the start */
          flex-direction: row; /* Arrange items in a row */
          margin-bottom: 0;

          .icon {
            margin-right: 0.5rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}