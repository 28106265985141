.contact-us {
  padding: 4rem 3rem;
  background: linear-gradient(45deg, #e7ce67, #40BB73, #F9E79F); /* Gradient background */

  .contact-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Left Section - Text */
    .contact-us-text {
      width: 50%;

      h2 {
        font-size: 40px;
        font-family: 'Krub', sans-serif;
        font-weight: bold;
        color: #054821;
        margin-bottom: 1rem;
      }

      p {
        font-size: 22px;
        color: #000000;
        margin-bottom: 2rem;
      }

      .contact-form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .input-group {
          display: flex;
          justify-content: space-between;
          gap: 1rem;

          input {
            width: 48%;
            padding: 1rem;
            border: 2px solid #ccc;
            border-radius: 8px;
            font-size: 16px;
            color: #054821;
          }
        }

        textarea {
          width: 100%;
          padding: 1rem;
          border: 2px solid #ccc;
          border-radius: 8px;
          font-size: 16px;
          color: #054821;
          resize: vertical;
          height: 150px;
        }

        .send-btn {
          background: #054821; /* Same gradient as background */
          padding: 1rem;
          border: none;
          border-radius: 8px;
          color: white;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          width: 20%;

          &:hover {
            background: white;
            color: #054821;
          }
        }
      }
    }

    /* Right Section - Image */
    .contact-us-image {
      width: 45%;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .contact-us-container {
      flex-direction: column;
      align-items: center;
    }

    .contact-us-text {
      width: 200%;
      text-align: center;
      margin-bottom: 2rem;
    }

    .contact-us-image {
      width: 90%; /* Increase image width */
    }

    .contact-form {
      width: 100%;
    }

    .input-group {
      flex-direction: column;
      width: 100%;
      gap: 1rem;

      input {
        width: 100%;
      }
    }

    textarea {
      width: 100%; /* Ensure textarea takes full width */
    }

    .send-btn {
      width: 50%; /* Increase button width */
    }
  }
}